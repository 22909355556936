@media only screen and (max-width: 768px) {
	.yourIdea {
		padding: 40px 0;
		h2 {
			font-size: 1.5rem;
		}
		p {
			font-size: 13px;
		}
	}
	.yourIdeaInner {
		ul {
			margin-top: 1.5rem;
			margin-bottom: 0.1rem;
			li {
				a {
					padding: 5px 25px;
					font-size: 13px;
				}
			}
		}
	}
}
@media only screen and (max-width: 600px) {
	.yourIdea {
		h2 {
			font-size: 1.3rem;
			margin-bottom: 0.5rem;
		}
	}
	.yourIdeaInner {
		ul {
			flex-wrap: wrap;
			li {
				flex: 0 0 100%;
				max-width: 100%;
				&:not(:last-child) {
					margin: 0 0 20px;
				}
				a {
					color: #2b2b2b;
					font-size: 14px;
					display: inline-block;
					padding: 5px 26px;
					&.timeToTalk {
						border-color: #616161;
						background: #fff;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 480px) {
}
