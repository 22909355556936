@media only screen and (max-width: 768px) {
	.story {
		padding: 3rem 0;
		h2 {
			font-size: 2.5rem;
		}
		h5 {
			font-size: 1rem;
		}
	}
}
@media only screen and (max-width: 600px) {
	.story {
		padding: 2rem 0;
		h2 {
			font-size: 1.7rem;
		}
		h5 {
			font-size: 0.8rem;
		}
		&:after {
			width: 100%;
			height: 450px;
		}
	}
}
