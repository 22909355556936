@media only screen and (max-width: 767px) {
	.blogDetails {
		.box-date {
			width: 72px;
			top: auto;
			left: 10px;
			bottom: 10px;
			background: rgba(255, 255, 255, 0.76);
			padding: 10px;
			backdrop-filter: blur(5px);
			.date {
				color: #2f2f2f;
				font-size: 3.12em;
				line-height: 52px;
			}
			.month {
				font-size: 0.886em;
				right: 8px;
				top: 40px;
				line-height: normal;
			}
		}
	}
}
