.upload-img-box {
	max-width: 300px;
	margin: 0 0 30px 0;
}

.upload-img-wrap {
	max-width: 300px;
	width: 100%;
	input {
		&[type='file'] {
			display: none;
			+ {
				label {
					height: 200px;
					border: 2px dashed #d9d9d9;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					transition: all 0.3s ease-in-out;
					position: relative;
					&:hover {
						border-color: #78bc4c;
					}
					.upload-text {
						text-align: center;
						i {
							font-size: 40px;
							color: #78bc4c;
							transition: all 0.3s ease-in-out;
						}
						p {
							margin-top: 10px;
							font-size: 16px;
						}
					}
					.preview-img {
						// width: 300px;
						img {
							height: 200px;
							object-fit: cover;
							padding: 10px;
							width: 100%;
						}
					}
				}
			}
		}
	}
}

$vidLbl: 250px;
.upload-video-wrap {
	input {
		&[type='file'] {
			display: none;
			+ {
				label {
					height: $vidLbl;
					border: 2px dashed #d9d9d9;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					transition: all 0.3s ease-in-out;
					position: relative;
					&:hover {
						border-color: #78bc4c;
					}
					.upload-text {
						text-align: center;
						i {
							font-size: 40px;
							color: #78bc4c;
							transition: all 0.3s ease-in-out;
						}
						p {
							margin-top: 10px;
							font-size: 16px;
						}
					}
				}
			}
		}
	}
}
.video-preview {
	width: 100%;
	height: $vidLbl;
	> {
		div {
			height: 100% !important;
			padding: 0 !important;
		}
	}
	.video-react {
		.video-react-big-play-button {
			font-size: 5em !important;
		}
	}
}
