@media only screen and (max-width: 991px) {
	.sectionTag.secTagLeft {
		top: 6.125rem;
		left: 1.125rem;
	}
	.sgbLeft {
		width: 50%;
	}
	.sgbRight {
		max-width: none;
		width: 50%;
		margin-left: 0px;
		margin-right: 68px;
	}
	.sectionTag.secTagRight {
		top: 6.125rem;
		right: 1.125rem;
	}
}

@media only screen and (max-width: 767px) {
	.sgbLeft {
		width: 100%;
	}
	.sgbRight {
		width: 100%;
		text-align: center;
		order: -1;
	}

	.gdAgencyLeftInner {
		h3 {
			font-size: clamp(1.3125rem, 0.718rem + 2.901vw, 4rem);
		}
	}
}
