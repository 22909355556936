@import 'assets/styles/variable.scss';
.goodBusiness {
	background: $bg-1;
}

.sgbRight {
	max-width: none;
	width: 38%;
	margin-left: 0px;
	margin-right: 150px;
	padding: 30px;
}
.sgbLeft {
	width: 62%;
	margin-top: auto;
	margin-bottom: auto;
	position: relative;
}

.gdAgencyLeftInner {
	h3 {
		font-weight: 300;
		font-size: clamp(2.3125rem, 1.71831rem + 1.90141vw, 4rem);
		color: $bg-6;
		span {
			font-weight: 700;
			display: block;
		}
	}
	p {
		font-size: clamp(1rem, 0.955986rem + 0.140845vw, 1.125rem);
		line-height: 1.64;
		color: $bg-6;
	}
}
