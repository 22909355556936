.alert-box {
	.alert {
		padding: 10px;
		font-weight: 600;
		i {
			margin-right: 8px;
		}
		.btn-close {
			padding: 0;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
