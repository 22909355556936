.preload {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 9500;
	background-color: #ffffff;
}
.preload .logo-container {
	position: relative;
	display: block;
	overflow: hidden;
	width: 270px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.preload .logo-container .logo {
	width: 270px;
	z-index: 9002;
	position: relative;
	display: block;
}
.preload .logo-container .logo-curtain {
	position: absolute;
	right: 1px;
	left: 1px;
	bottom: 1%;
	top: 1px;
	z-index: 9001;
	overflow: hidden;
	// opacity: 0;
	background-color: #eeeeec;
}
.preload .logo-container .logo-curtain div {
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 100%;
	background: url(../../assets/images/common-image/background-pixel.png);
	background-size: cover;
	// background: #01c28d;
}
