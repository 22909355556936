@media only screen and (max-width: 767px) {
	.banner {
		height: 400px;
		padding: 30px 0;
		display: none !important;
	}
	.homeBanMobArea {
		display: block;
	}
}
