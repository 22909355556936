@import 'assets/styles/variable.scss';

.yourIdea {
	background: #f2f6f5;
	padding: 80px 0;
	position: relative;
	z-index: 1;
	&:after,
	&:before {
		content: '';
		position: absolute;
		width: 340px;
		height: 100%;
		background-size: contain !important;
		bottom: 0;
		z-index: -1;
	}
	&:after {
		left: 0;
		background: url('../../assets/images/common-image/leaf-bg-l.png') bottom left no-repeat;
	}
	&:before {
		right: 0;
		background: url('../../assets/images/common-image/leaf-bg-r.png') bottom right no-repeat;
	}
	h2 {
		color: #333;
		font-size: 2.5rem;
		text-transform: uppercase;
		font-weight: 700;
		padding: 0;
		margin-bottom: 1.5rem;
	}
}

.yourIdeaInner {
	max-width: 700px;
	width: 100%;
	margin: 0 auto;
	font-size: 18px;
	line-height: 1.8;
	font-weight: 600;

	ul {
		margin-top: 2.5rem;
		li {
			&:not(:last-child) {
				margin-right: 30px;
			}
			a {
				padding: 10px 40px;
				border-radius: 30px;
				display: block;
				font-weight: 600;
				color: $bg-1;
				font-size: 16px;
				&.timeToTalk {
					border: $bg-1 1px solid;
				}
				svg {
					font-size: 30px;
					position: relative;
					transition: all ease-in-out 0.4s;
					margin-left: 5px;
				}
				&:hover {
					svg {
						transform: translateX(10px);
					}
				}
			}
		}
	}
}
