@media only screen and (max-width: 800px) {
	.homeCaseStudy {
		display: none !important;
	}
	.mobile-case-slider {
		display: block !important;
		padding: 1.4rem 0 0 0;
		.mobile-slider-box {
			.mobile-slider-info-box {
				display: block;
				padding: 1rem;
				// box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
				background: #f9f9f9;
				border-radius: 0.5rem;
				.image-wrap {
					width: 70%;
					padding: 1rem 0 0 0;
				}
				.mobile-slider-info {
					margin-top: 1.8rem;
					text-align: center;
					.mobile-slider-info-logo {
						max-width: 13rem;
						margin: 0 auto;
					}
					span {
						margin: 0.5rem 0;
						&.caseStudy {
							display: inline-block;
							padding: 0 2rem;
							font-size: 0.7rem;
							line-height: 2.5rem;
						}
					}
				}
			}
		}
	}
	.owl-arrow-box {
		.owl-dots {
			position: absolute;
			left: calc(100% - 7.5rem);
			top: 1.4rem;
			margin: 0 !important;
			.owl-dot {
				span {
					height: 0.5rem !important;
					width: 0.5rem !important;
					margin: 0 3px !important;
					transition: all 0.5s ease-in-out !important;
				}
				&.active {
					span {
						background: #01c28d !important;
						width: 1.2rem !important;
					}
				}
			}
		}
		.owl-stage {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
}
