.PortfolioTechnologis {
	background: #f9f9f9;
	padding: 2rem 0;
}
.technologiesList {
	h4 {
		padding: 0;
		font-weight: 700;
		font-size: 30px;
	}
	ul {
		li {
			padding: 0 20px;
			span {
				padding: 30px;
				background: #fff;
				display: block;
				border-radius: 10px;
				border: #eee 1px solid;
				img {
					display: block;
					width: 100%;
				}
			}
		}
	}
}
