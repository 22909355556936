@font-face {
	font-family: 'Turbinado';
	src: url('TurbinadoBold.woff2') format('woff2'), url('TurbinadoBold.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-BlackItalic.woff2') format('woff2'), url('Montserrat-BlackItalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Black.woff2') format('woff2'), url('Montserrat-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-BoldItalic.woff2') format('woff2'), url('Montserrat-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Bold.woff2') format('woff2'), url('Montserrat-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-ExtraBold.woff2') format('woff2'), url('Montserrat-ExtraBold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-ExtraBoldItalic.woff2') format('woff2'), url('Montserrat-ExtraBoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-ExtraLightItalic.woff2') format('woff2'), url('Montserrat-ExtraLightItalic.woff') format('woff');
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-ExtraLight.woff2') format('woff2'), url('Montserrat-ExtraLight.woff') format('woff');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Italic.woff2') format('woff2'), url('Montserrat-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Light.woff2') format('woff2'), url('Montserrat-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-LightItalic.woff2') format('woff2'), url('Montserrat-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Medium.woff2') format('woff2'), url('Montserrat-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-MediumItalic.woff2') format('woff2'), url('Montserrat-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Regular.woff2') format('woff2'), url('Montserrat-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-SemiBoldItalic.woff2') format('woff2'), url('Montserrat-SemiBoldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-SemiBold.woff2') format('woff2'), url('Montserrat-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-Thin.woff2') format('woff2'), url('Montserrat-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Rg';
	src: url('ProximaNova-Bold.woff2') format('woff2'), url('ProximaNova-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Th';
	src: url('ProximaNova-Extrabld.woff2') format('woff2'), url('ProximaNova-Extrabld.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('Montserrat-ThinItalic.woff2') format('woff2'), url('Montserrat-ThinItalic.woff') format('woff');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Bl';
	src: url('ProximaNova-Black.woff2') format('woff2'), url('ProximaNova-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Th';
	src: url('ProximaNovaT-Thin.woff2') format('woff2'), url('ProximaNovaT-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova Rg';
	src: url('ProximaNova-Regular.woff2') format('woff2'), url('ProximaNova-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
