@import 'assets/styles/variable.scss';
.cnFrmTitle {
	margin-bottom: 2.5rem;
	max-width: 500px;
	margin: 0 auto 3.125rem;
	h2 {
		font-weight: 700;
		padding: 0;
		margin-bottom: 0.938rem;
	}
	p {
		font-size: 1.125rem;
	}
}
.address {
	height: 100%;
	background: url(../../assets/images/common-image/q-white.png) #edf9f5 bottom right no-repeat;
	padding: 1.875rem;
	background-size: 23.125rem;
	h4 {
		font-weight: 700;
	}
	p {
		line-height: 2.3;
		font-weight: 500;
	}
}

.contactFormArea {
	padding: 3rem 0;
	.enqCon {
		&:not(:last-child) {
			margin-bottom: 1.875rem;
		}
	}
	.form-group {
		margin-bottom: 25px;
		position: relative;
		label {
			color: $bg-3;
			margin-bottom: 0.938rem;
			font-weight: 600;
		}
		.form-control {
			background: 0 0;
			border: 1px solid #dfdfdf;
			height: 2.813rem;
		}
		textarea.form-control {
			background: 0 0;
			height: 6.25rem;
			resize: none;
		}
		i {
			position: absolute;
			top: 0.813rem;
			right: 0.813rem;
			color: $bg-10;
		}
	}
}
.topCon {
	margin-bottom: 1.875rem;
	p {
		font-weight: 500;
		font-size: 1.125rem;
		line-height: 1.875rem;
		color: $bg-4;
		max-width: 300px;
	}
}
.enqCon {
	background: #f9f9f9;
	padding: 3.125rem;
	border-radius: 8px;
	text-align: center;
	h3 {
		color: $bg-3;
		font-size: 1.875rem;
		font-weight: 700;
	}
	p {
		color: $bg-4;
		font-size: 1.125rem;
		line-height: 2rem;
		margin-bottom: 0.625rem;
	}
	a {
		color: $bg-1;
		font-size: 0.875rem;
		text-decoration: underline;
		font-weight: 600;
		&:hover {
			color: $bg-3;
			text-decoration: none;
		}
	}
}
.contactBanner {
	background: #f9f9f9;
	img {
		width: 100%;
		display: block;
	}
}

.connectUs {
	position: sticky;
	top: 11.875rem;
	h3 {
		font-weight: 700;
		font-size: 1.5rem;
	}
	p {
		margin-bottom: 0.938rem;
	}
	ul {
		li {
			&:not(:last-child) {
				margin-bottom: 0.938rem;
			}
			a {
				color: $bg-3;
				// text-decoration: underline;
				font-weight: 500;
				font-size: 1rem;
				svg {
					color: $bg-1;
					margin-right: 5px;
				}
				&:hover {
					color: $bg-1;
				}
			}
		}
	}
}
.cntForm {
	padding: 0 1.25rem;
}

.projectWork {
	padding-bottom: 3.125rem;
}

.getDirection {
	position: absolute;
	bottom: 1.25rem;
	left: 1.25rem;
	color: $bg-3;
	font-weight: bold;
	text-decoration: underline;
	img {
		width: 1.5rem;
		margin-right: 0.625rem;
		position: relative;
		top: -2px;
	}
	&:hover {
		color: $bg-1;
	}
}

.sendBtn {
	border: none;
	display: block;
	text-align: center;
	cursor: pointer;
	text-transform: uppercase;
	outline: none;
	overflow: hidden;
	position: relative;
	color: $bg-6;
	font-weight: 700;
	font-size: 0.938rem;
	background-color: $bg-3;
	padding: 0.75rem 3.75rem;
	box-shadow: 0 5px 0.938rem rgba(0, 0, 0, 0.2);
	span {
		position: relative;
		z-index: 1;
	}
	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		height: 490%;
		width: 140%;
		background: $bg-1;
		-webkit-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
		-webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
		transform: translateX(-98%) translateY(-25%) rotate(45deg);
	}
	&:hover {
		&:after {
			-webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
			transform: translateX(-9%) translateY(-25%) rotate(45deg);
		}
	}
}

.captcha {
	img {
		width: 22rem;
	}
}
