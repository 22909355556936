@import 'assets/styles/variable.scss';

.StobGetBusinessSection {
	padding: 4rem 0;
	position: relative;
	overflow: hidden;
	&:after {
		content: '';
		position: absolute;
		right: -150px;
		top: 10%;
		height: 300px;
		width: 300px;
		border-radius: 50%;
		z-index: -1;
		transform: translateY(-50%);
		animation: jump15p 5s linear infinite alternate;
		border: #f6f6f6 5px solid;
	}
	&:before {
		content: '';
		position: absolute;
		right: -120px;
		top: 14%;
		height: 300px;
		width: 300px;
		border-radius: 50%;
		z-index: -1;
		transform: translateY(-50%);
		animation: jump10p 3s linear infinite alternate;
		border: #ccffe6 2px solid;
	}
}
.StobGetBusinessLeft {
	h2 {
		color: #2f2f2f;
		font-weight: 700;
		font-size: 3rem;
		position: relative;
		z-index: 1;
		span {
			font-weight: 300;
			display: block;
			font-size: 2.8rem;
		}
	}
	&::after {
		content: '';
		position: absolute;
		background: #01c28d;
		background-size: auto;
		background-size: cover;
		height: 18.75rem;
		width: 18.75rem;
		top: 50%;
		transform: translateY(-50%);
		z-index: -1;
		left: -50px;
		opacity: 0.3;
		left: -36px;
	}
}
.StobGetBusinessRight {
	p {
		font-size: 1.1rem;
		line-height: 1.9;
	}
}
