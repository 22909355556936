@import 'assets/styles/variable.scss';

.sercicesBanner {
	padding: 5rem 6.25rem;
	position: relative;
	background: #f9f9f9;
	overflow: hidden;
}
.sercicesBannerRight {
	flex: 0 0 50%;
	max-width: 50%;
	h6 {
		color: $bg-1;
		font-size: 1.3vw;
	}
	h2 {
		font-size: 4vw;
		font-weight: 700;
		line-height: 1.1;
	}
	p {
		font-size: 1.2vw;
	}
}

.sercicesBannerLeft {
	z-index: 1;
	padding-right: 4.375rem;
	flex: 0 0 50%;
	max-width: 50%;
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		width: 70%;
		height: calc(100% + 10rem);
		background: #87c2e6;
		z-index: -1;
		left: -100px;
		transform: translateY(-50%);
		opacity: 0.4;
	}
	img {
		display: block;
		width: 100%;
	}

	.ciBannerLeftInner {
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			width: 2px;
			height: 70%;
			background: #01c28d;
			right: -30px;
			transform: translateY(-50%);
			opacity: 0.3;
		}
	}
}
