@import 'assets/styles/variable.scss';
.accessibleDesign {
	background: rgb(2, 211, 124);
	background: -moz-linear-gradient(-45deg, rgba(2, 211, 124, 1) 0%, rgba(1, 127, 102, 1) 100%);
	background: -webkit-linear-gradient(-45deg, rgba(2, 211, 124, 1) 0%, rgba(1, 127, 102, 1) 100%);
	background: linear-gradient(135deg, rgba(2, 211, 124, 1) 0%, rgba(1, 127, 102, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#02d37c', endColorstr='#017f66',GradientType=1 );
}
.sgbLeftImg {
	img {
		width: 100%;
		display: block;
	}
}
