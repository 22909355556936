@media only screen and (max-width: 600px) {
	.overView {
		padding: 1.5rem 0;
	}
	.overViewInner {
		p {
			font-size: 0.8rem;
			line-height: 1.5;
		}
	}
}
