@import 'assets/styles/variable.scss';
.not-found-wrap {
	background-size: cover !important;
	min-height: 100vh;
	.eror-404-text {
		text-align: center;
		h3 {
			font-size: 220px;
			font-weight: 700;
			letter-spacing: 8px;
			line-height: 1;
			padding-bottom: 0;
			margin-bottom: 50px;
			color: #6b6b6b;
			// text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
		}
		p {
			font-size: 24px;
			color: #525252;
			padding-bottom: 50px;
		}
		.error-bkhome {
			display: inline-block;
			padding: 0 50px;
			line-height: 50px;
			font-size: 16px;
			background-color: $bg-1;
			color: #fff;
			text-transform: uppercase;
			font-weight: 500;
			border-radius: 30px;
			&:hover {
				background-color: $bg-3;
			}
		}
	}
}

.zero {
	margin: 0 10px;
	img {
		width: 180px;
		animation: jump10p 3s linear infinite;
		position: relative;
		top: -20px;
	}
}
