// @import 'assets/admin/styles/styles.scss';
@import 'assets/admin/styles/variable.scss';
.navbar {
	border-bottom: $navbar-border-bottom;
	box-shadow: $navbar-box-shadow;
	// @include media-breakpoint-down(md) {
	//     width: 100vw;
	// }
}

.navbar .avatar {
	margin-top: -15px;
	margin-bottom: -15px;
}

.navbar-nav {
	direction: ltr;
}

.navbar-align {
	margin-left: auto;
}

.navbar-bg {
	background: $navbar-bg;
}

.navbar-brand {
	font-weight: $navbar-brand-font-weight;
	font-size: $navbar-brand-font-size;
	padding: $navbar-brand-padding-y $navbar-brand-padding-x;
	color: $navbar-brand-color;
	display: block;
	svg,
	.feather {
		color: $navbar-brand-icon-color;
		height: 24px;
		width: 24px;
		margin-left: -0.15rem;
		margin-right: 0.375rem;
		margin-top: -0.375rem;
	}
}

.nav-icon,
.nav-flag {
	padding: 0.1rem 0.8rem;
	display: block;
	font-size: 1.5rem;
	color: $gray-600;
	transition: $transition-appearance-fast;
	line-height: 1.4;
	&:after {
		display: none !important;
	}
	&:hover,
	&.active {
		color: $primary;
	}
	svg,
	.feather {
		width: 20px;
		height: 20px;
	}
}

.nav-item {
	.indicator {
		background: $primary;
		box-shadow: $box-shadow;
		border-radius: 50%;
		display: block;
		height: 18px;
		width: 18px;
		padding: 1px;
		position: absolute;
		top: 0;
		right: -8px;
		text-align: center;
		transition: top 0.1s ease-out;
		font-size: 0.675rem;
		color: $white;
	}
	&:hover .indicator {
		top: -4px;
	}
	a:focus {
		outline: 0;
	}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.navbar .avatar {
		max-height: 47px;
	}
}

// @include media-breakpoint-down(sm) {
//     .navbar {
//         padding: 0.75rem;
//     }
//     .nav-icon {
//         padding: .1rem .75rem;
//     }
//     .dropdown,
//     .dropleft,
//     .dropright,
//     .dropup {
//         position: inherit;
//     }
//     .navbar-expand .navbar-nav .dropdown-menu-lg {
//         min-width: 100%;
//     }
//     .nav-item .nav-link:after {
//         display: none;
//     }
// }
.nav-flag img {
	border-radius: 50%;
	width: 20px;
	height: 20px;
	object-fit: cover;
}

.navbar input {
	direction: ltr;
}

.navbar-nav .dropdown-menu {
	box-shadow: $box-shadow;
}

.custom-dropdown {
	&.dropdown {
		.dropdown-menu {
			&.show {
				animation-name: dropdownAnimation;
				animation-duration: 0.25s;
				animation-iteration-count: 1;
				animation-timing-function: ease;
				animation-fill-mode: forwards;
			}
		}
	}
}

@keyframes dropdownAnimation {
	from {
		opacity: 0;
		transform: translateY(-8px);
	}
	to {
		opacity: 1;
		transform: translate(0);
	}
}

.dropdown-toggle:after {
	border: solid;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 2px;
	transform: rotate(45deg);
}

.dropdown-item {
	transition: $transition-appearance-fast;
}

.dropdown-menu {
	// top: 100% !important;
	top: auto;
}

.dropdown-menu-lg {
	min-width: 10rem * 2;
}

.dropdown .list-group .list-group-item {
	border-width: 0;
	border-bottom-width: 1px;
	margin-bottom: 0;
	&:first-child,
	&:last-child {
		border-radius: 0;
	}
	&:hover {
		background: $gray-100;
	}
}

.dropdown-menu-header {
	padding: $spacer * 0.75;
	text-align: center;
	font-weight: $font-weight-bold;
	border-bottom: 1px solid $gray-300;
}

.dropdown-menu-footer {
	padding: $spacer * 0.5;
	text-align: center;
	display: block;
	font-size: $font-size-sm;
}

.feather {
	width: 18px;
	height: 18px;
	stroke-width: 2;
}

.feather-sm {
	width: 14px;
	height: 14px;
}

.feather-lg {
	width: 36px;
	height: 36px;
}

.avatar {
	width: 35px;
	height: 35px;
	box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
	border-radius: 50% !important;
	object-fit: cover;
}

.avatar-lg {
	width: 64px;
	height: 64px;
}

.avatar-title {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	color: $primary;
}

.hamburger,
.hamburger:before,
.hamburger:after {
	cursor: pointer;
	border-radius: 1px;
	height: 3px;
	width: $hamburger-width-middle;
	background: $gray-700;
	display: block;
	content: '';
	transition: $transition-appearance-fast;
}

.hamburger {
	position: relative;
}

.hamburger:before {
	top: -7.5px;
	width: $hamburger-width-top;
	position: absolute;
}

.hamburger:after {
	bottom: -7.5px;
	width: $hamburger-width-bottom;
	position: absolute;
}

.sidebar-toggle:hover {
	.hamburger,
	.hamburger:before,
	.hamburger:after {
		background: $primary;
	}
}

.hamburger-right {
	&,
	&:before,
	&:after {
		right: 0;
	}
}

a.list-group-item {
	text-decoration: none;
}

.carat-icon {
	span {
		i {
			margin-left: 5px;
		}
	}
	&::after {
		display: none !important;
	}
}

nav {
	&.navbar {
		padding: 0.5rem 1.5rem;
	}
}

.dropdown-menu-lg {
	min-width: 20rem !important;
}

.db-header {
	.nav-item {
		.indicator {
			top: -6px;
			right: -10px;
		}
		.nav-icon {
			font-size: 20px;
		}
		.dropdown-menu {
			.dropdown-item {
				font-size: 15px;
				i {
					font-size: 14px;
					margin-right: 6px;
				}
				&:hover {
					color: #fff;
				}
			}
		}
	}
	.dropdown-menu-header {
		color: #599731;
	}
	.list-group-item {
		h4 {
			padding-bottom: 3px;
			font-size: 14px;
		}
		p {
			padding-bottom: 0;
			font-size: 12px;
			color: #6c757d;
			&.rcv-time {
				font-size: 10px;
			}
		}
	}
}

.js-sidebar {
	&.collapsed {
		+ {
			.main {
				.sidebar-toggle {
					.hamburger {
						transition: all 0.3s ease-in-out;
						&::before {
							top: -5px;
							width: 16px;
							position: absolute;
							transform: rotate(45deg);
							left: 10px;
							transition: all 0.3s ease-in-out;
						}
						&::after {
							bottom: -5px;
							width: 16px;
							position: absolute;
							transform: rotate(-45deg);
							left: 10px;
							transition: all 0.3s ease-in-out;
						}
					}
				}
			}
		}
	}
}

.navbar-expand {
	.navbar-nav {
		.dropdown-menu {
			box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.15);
			border: none;
		}
	}
}
