@import 'assets/styles/variable.scss';
// .BlogBanner{
//     height: 600px;
// 	overflow: hidden;
// 	h2 {
// 		font-weight: 700;
// 	}
// 	p {
// 		font-size: 1.3vw;
// 	}
// 	.caseStudy {
// 		margin-top: 1.8rem;
// 	}
// }

// .BlogBannerImg {
// 	width: 40%;
//     position: absolute;
//     right: 0;
//     top: 0;
//     animation: jump10p 5s linear infinite;
//     padding-right: 0 !important;
// 	img {
// 		display: block;
// 		width: 100%;
// 	}
// }

.BlogBanner {
	height: 300px;
}
