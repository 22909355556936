@import 'assets/styles/variable.scss';
.hardWorkArea {
	padding: 4rem 0;
	.sectionInner {
		&.pr-20 {
			padding-right: 20px;
		}
		&.pl20 {
			padding-left: 20px;
		}
		h3 {
			font-weight: 700;
		}
		ul {
			li {
				position: relative;
				padding-left: 24px;
				&:not(:last-child) {
					margin-bottom: 10px;
				}
				&:after {
					content: '';
					position: absolute;
					top: 7px;
					left: 0;
					width: 10px;
					height: 10px;
					background: $bg-1;
					border-radius: 50%;
				}
			}
		}
	}
	.sectionImg {
		padding: 0;
	}
}
