@import 'assets/styles/variable.scss';
.rightMatch {
	background: #2f2f2f;
	padding: 6.25rem 0;
	position: relative;
	overflow: hidden;
	.rightMatchInner {
		position: relative;
		z-index: 1;
	}
	h2 {
		color: #fff;
		font-size: 2.5rem;
		padding-bottom: 1.9rem;
		text-transform: uppercase;
	}
	h4 {
		color: #fff;
		font-size: 1.25rem;
		padding-bottom: 1.9rem;
		padding-bottom: 0;
		span {
			display: block;
			margin-top: 1.9rem;
			a {
				color: $bg-1;
				font-weight: 700;
				&:hover {
					transform: scale(1.2);
				}
			}
		}
	}
	.rightMatchBgLeft {
		position: absolute;
		left: -3.125rem;
		bottom: 0;
	}
	.rightMatchBgRight {
		position: absolute;
		right: -3.125rem;
		bottom: 0;
	}
}
