@import 'assets/styles/variable.scss';

.PortfolioReliableApp {
	background: url(../../assets/images/common-image/portfolio/app-bg.png) left top no-repeat;
	padding: 40px 0;
	.prAppImg {
		filter: drop-shadow(14px 12px 20px rgba(0, 0, 0, 0.14));
		img {
			display: inline-block;
			max-width: 100%;
		}
	}
	.prAppInner {
		h3 {
			font-weight: 600;
			line-height: 1.8;
			a {
				color: $bg-1;
				text-decoration: underline;
				font-style: italic;
			}
		}
	}
}
