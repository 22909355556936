.login-select {
	.lbl-heading {
		color: #fff;
		margin-bottom: 5px;
		font-size: 14px;
		font-weight: 600;
	}
	.login-select-style {
		padding: 5px 30px 5px 5px;
		border: #78bc4c 1px solid !important;
		background-color: transparent;
		color: #c9c9c9 !important;
		height: 40px;
		&:focus {
			background-color: transparent;
		}
		option {
			color: #000;
			background: #cff5b6;
			// &:focus{
			//     background-color: #78bc4c !important;
			// }
		}
	}
}

.register-catagory {
	p {
		color: #fff;
		margin-bottom: 5px;
		font-size: 14px;
		font-weight: 600;
	}
	ul {
		li {
			input[type='radio'] {
				display: none;
			}
			&:not(:last-child) {
				margin-right: 20px;
			}
			label {
				color: #fff;
				margin-bottom: 5px;
				font-size: 14px;
				font-weight: 600;
				cursor: pointer;
				&::after {
					background-color: #78bc4c;
				}
			}
		}
	}
}

.login-select2 {
	.lbl-heading {
		color: #fff;
		margin-bottom: 5px;
		font-size: 14px;
		font-weight: 600;
	}
	.css-1s2u09g-control {
		border: #78bc4c 1px solid !important;
		background-color: transparent;
		color: #c9c9c9 !important;
		height: 40px;
		// .css-319lph-ValueContainer{
		// }
		.css-14el2xx-placeholder {
			color: #c9c9c9;
		}
		.css-6j8wv5-Input,
		.css-qc6sy-singleValue {
			color: #fff !important;
		}
		.css-1hb7zxy-IndicatorsContainer {
			.css-1okebmr-indicatorSeparator {
				background-color: #78bc4c;
			}
			.css-tlfecz-indicatorContainer {
				svg {
					color: #78bc4c;
				}
			}
		}
	}
}

.style-rtl {
	.register-catagory {
		ul {
			li {
				&:not(:last-child) {
					margin-left: 20px;
				}
			}
		}
	}
}

.cmn-blk-modal {
	.otp-input-wrap {
		.otp-box {
			input {
				background: transparent;
				color: #fff;
			}
			margin-bottom: 20px;
		}
	}
}
