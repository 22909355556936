$headerbtmBg: #3a3a3b;
$primeryColor: #4b8e20;
$seconderyColor: #78bc4c;
$tartiaryColor: #3a3a3b;
$primeryHover: #3a3a3b;
$seconderyHover: #4b8e20;
$weatherCloud: #07a4e6;
$weatherSun: #f9ac06;
$bg-1: #01c28d;
$bg-2: #b7e488;
$bg-3: #2f2f2f;
$bg-4: #676565;
$bg-5: #aeafad;
$bg-6: #fff;
$bg-7: #f8f1ef;
$bg-8: #e2faf4;
$bg-9: #d9e8fc;
$bg-10: #4dd4af;
$bg-11: #66daba;
$bg-12: #04a379;
$bg-13: #757575;
$bg-14: #c8fdee;
