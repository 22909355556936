@import 'assets/styles/variable.scss';
.partnerArea {
	padding: 30px 0;
	// background: #f6f6f6;
}
.partnerBox {
	a {
		// border: #eee 1px solid;
		padding: 0 15px;
		display: block;
		//  background: #fff;
		border-radius: 6px;
		img {
			display: block !important;
			filter: grayscale(1);
			opacity: 0.3 !important;
			&:hover:hover {
				filter: none;
				opacity: 1 !important;
			}
		}
	}
}
