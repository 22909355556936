@import 'assets/styles/variable.scss';

.howWeWorkBanner {
	background: url(../../assets/images/common-image/patern.png) repeat #fff;
	padding: 6rem 0;
}

.howWeWorkLeft {
	h2 {
		color: #2f2f2f;
		font-size: 3.7vw;
		font-family: 'Proxima Nova Bl';
		line-height: 1;
		letter-spacing: 0px;
	}
	p {
		color: #2f2f2f;
		font-size: 1.5vw;
		font-family: 'Proxima Nova Rg';
	}
}

.howWeWorkRight {
	position: absolute;
	right: 3rem;
	top: 50%;
	transform: translateY(-50%);
	width: 32%;
	text-align: center;
	img {
		display: block;
		width: 100%;
		animation: jump10p 5s linear infinite;
	}
}
// @keyframes jump10p {
// 	0% {
// 		transform: translateZ(0);
// 	}
// 	40% {
// 		transform: translate3d(0, 10px, 0);
// 	}
// 	100% {
// 		transform: translateZ(0);
// 	}
// }
.howWeWorkLeft {
	.caseStudy {
		margin-top: 30px;
	}
}
