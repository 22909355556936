.otp-input-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	input[type='number'] {
		appearance: textfield;
		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			appearance: none;
			margin: 0;
		}
	}
	.otp-box {
		position: relative;
		.login-input-style {
			text-align: center;
			font-size: 25px;
			font-weight: 600;
			padding: 10px;
			width: 50px !important;
			height: 60px;
			&::placeholder {
				font-size: 20px;
			}
		}
		&:not(:last-child) {
			padding-right: 20px;
		}
		&:not(:first-child) {
			padding-left: 40px;
			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				background: #fff;
				width: 15px;
				height: 1px;
			}
		}
		&.otp-box-list {
			> {
				div {
					&:not(:first-child) {
						padding-left: 30px;
					}
				}
			}
			.login-input-style {
				outline: none;
				box-shadow: none;
				border: none !important;
				border-bottom: #78bc4c 1px solid !important;
			}
			// div {
			//     position: relative;
			//     &:not(:first-child) {
			//         &::before {
			//             content: "";
			//             position: absolute;
			//             left: 0;
			//             top: 50%;
			//             transform: translateY(-50%);
			//             background: #fff;
			//             width: 15px;
			//             height: 1px;
			//         }
			//     }
			// }
		}
	}
}
