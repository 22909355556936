@import 'assets/styles/variable.scss';
.admin-white-box {
	width: 100%;
	background-color: #fff;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.03);
}

.rdt_TableCell {
	div:first-child {
		white-space: inherit !important;
		overflow: visible !important;
		text-overflow: inherit !important;
	}
}
.cmn-btn-tag-wrap {
	margin-top: 1.4rem;
	.cmn-btn-tag {
		border: none;
		display: block;
		text-align: center;
		cursor: pointer;
		text-transform: uppercase;
		outline: none;
		overflow: hidden;
		position: relative;
		color: $bg-6;
		font-weight: 700;
		font-size: 0.938rem;
		background-color: $bg-3;
		padding: 0.75rem 3.75rem;
		box-shadow: 0 5px 0.938rem rgba(0, 0, 0, 0.2);
		&:hover {
			background: #01c28d;
		}
	}
}
.img-block {
	display: block;
	width: 100%;
}
