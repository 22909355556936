@import 'assets/styles/variable.scss';
.login-regbg {
	min-height: 100vh;
	background-size: cover !important;
	.login-reg-wrap {
		max-width: 650px;
		padding: 50px;
		width: 100%;
		background-color: $bg-6;
		border-radius: 10px;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 35px -9px;
		.login-reg-box {
			padding: 20px;
			.log-reg-logo {
				display: block;
				max-width: 300px;
				margin: 0 auto 30px;
				img {
					width: 100%;
					display: block;
				}
			}
		}
	}
}

.login-input {
	margin-bottom: 20px;
	.lbl-heading {
		color: $bg-3;
		margin-bottom: 5px;
		font-size: 14px;
		font-weight: 600;
	}
	.login-input-style {
		padding: 0 15px;
		border: #e1e1e0 1px solid !important;
		background: transparent;
		color: $bg-3 !important;
		height: 50px;
		box-shadow: #ffffff 0 0 0 50px inset;
		&::placeholder {
			color: #c9c9c9;
			font-size: 14px;
		}
		&:focus {
			background-color: transparent !important;
		}
	}
}

.add-icon {
	position: relative;
	.login-input-style {
		padding-right: 30px;
	}
	.pass-icon {
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		color: $bg-1;
		cursor: pointer;
	}
}

.remember-me {
	input[type='checkbox'] {
		display: none;
	}
	label {
		line-height: 1.2;
		color: $bg-3;
		text-transform: capitalize;
		&::before {
			border: 1px solid #78bc4c !important;
			background-color: #3a3a3b !important;
		}
		&::after {
			padding-top: 2px !important;
			padding-left: 4px !important;
			color: #78bc4c !important;
			font-size: 10px !important;
		}
	}
}

.forget-pass {
	.forget-pass-btn {
		color: #dfdfdf;
		font-size: 15px;
		text-align: right;
		display: block;
		text-transform: capitalize;
	}
}

.logreg-btn-wrap {
	text-align: center;
	.logreg-btn {
		background-color: $bg-1;
		border: none;
		outline: none !important;
		box-shadow: none;
		line-height: 2.5;
		color: $bg-6;
		font-size: 18px;
		border-radius: 5px;
		padding: 0 35px;
		margin: 20px 0;
		text-transform: uppercase;
		display: block;
		width: 100%;
		font-weight: 600;
		letter-spacing: 2px;
	}
	p {
		color: $bg-3;
		.logreg-btn2 {
			color: $bg-1;
			text-decoration: underline;
			font-weight: 600;
			margin-left: 5px;
		}
	}
}

.style-rtl {
	.forget-pass {
		.forget-pass-btn {
			text-align: left;
		}
	}
}
.login-reg-box {
	.log-back-home {
		position: absolute;
		right: -80px;
		top: 8px;
		.log-back-home-btn {
			background-color: #01c28d;
			border: none;
			color: #fff;
			font-size: 23px;
			border-radius: 50px;
			width: 60px;
			text-align: center;
			line-height: 60px;
		}
	}
}
