@media only screen and (max-width: 2560px) {
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1660px) {
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1366px) {
}

@media only screen and (max-width: 1350px) {
}

@media only screen and (max-width: 1280px) {
	.caseStudy {
		line-height: 2.7rem;
		padding: 0 1.6rem;
		font-size: 0.875rem;
	}
}

@media only screen and (max-width: 1204px) {
	.container {
		max-width: 100%;
	}
}

@media only screen and (max-width: 1135px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 1000px) {
}

@media only screen and (max-width: 991px) {
}

@media only screen and (max-width: 960px) {
}

@media only screen and (max-width: 910px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 885px) {
}

@media only screen and (max-width: 880px) {
}

@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 768px) {
	.aboutTitle {
		margin-bottom: 1.375rem;
	}
}

@media only screen and (max-width: 767px) {
	h2 {
		font-size: 1.7rem;
	}
	.p-h-40 {
		padding: 0 15px;
	}
	.p-h-30 {
		padding: 0 15px;
	}
	.aboutTitle {
		h2 {
			font-size: 1.7rem;
		}
	}
	body {
		font-size: 0.813rem;
	}
}

@media only screen and (max-width: 736px) {
	.p-v-40 {
		padding: 1.4rem 0;
	}
}

@media only screen and (max-width: 667px) {
}

@media only screen and (max-width: 600px) {
	h2 {
		font-size: 1.5rem;
	}
	.caseStudy {
		line-height: 2rem;
		padding: 0 1.4rem;
		font-size: 0.69rem;
	}
}

@media only screen and (max-width: 575px) {
	.aboutTitle h2 {
		font-size: 1.4rem;
	}
}

@media only screen and (max-width: 545px) {
}

@media only screen and (max-width: 480px) {
}

@media only screen and (max-width: 414px) {
}

@media only screen and (max-width: 384px) {
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 320px) {
}

@media only screen and (max-width: 240px) {
}
