@media only screen and (max-width: 880px) {
	.sercicesBanner {
		padding: 3rem 2.25rem;
	}
}
@media only screen and (max-width: 600px) {
	.sercicesBanner {
		padding: 1rem;
	}
	.sercicesBannerLeft {
		padding-right: 0;
		flex: 0 0 100%;
		max-width: 100%;
		.ciBannerLeftInner {
			&::before {
				display: none;
			}
		}
		&:after {
			top: -1rem;
			width: calc(100% + 2rem);
			height: 100px;
			left: -1rem;
			transform: translateY(0);
		}
	}
	.sercicesBannerRight {
		flex: 0 0 100%;
		max-width: 100%;
		position: relative;
		z-index: 1;
		margin-top: 0.8rem;
		text-align: center;
		h6 {
			font-size: 4vw;
		}
		h2 {
			font-size: 6vw;
			padding: 0 0 0.5rem;
		}
		p {
			font-size: 0.75rem;
		}
	}
}
