@import 'assets/styles/variable.scss';

.story {
	padding: 6rem 0;
	background: #f8f8f8;
	position: relative;
	z-index: 1;
	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 450px;
		height: 450px;
		background: url(../../assets/images/common-image/q-color.png) 0 0 no-repeat;
		background-size: contain;
		z-index: -1;
		opacity: 0.05;
		animation: jump30p 5s linear infinite;
	}
	h2 {
		font-weight: 700;
		font-size: 50px;
	}
	h5 {
		font-weight: 400;
		color: $bg-12;
		img {
			margin-right: 10px;
		}
	}
}
.storyInner {
	margin: 0 auto;
	max-width: 800px;
}

@keyframes jump30p {
	0% {
		transform: translateZ(0);
	}
	40% {
		transform: translate3d(0, 30px, 0);
	}
	100% {
		transform: translateZ(0);
	}
}
