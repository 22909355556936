@import 'assets/styles/variable.scss';
.comeOnBoard {
	padding: 4.25rem 0;
	.comeOnBoardBox {
		padding: 3rem 1rem;
		box-shadow: 0px 10px 14px -5px rgba(187, 187, 186, 0.31);
		position: relative;
		z-index: 1;
		transition: all ease-in-out 0.5s;
		text-align: center;
		display: block;
		height: 100%;
		&::after {
			content: '';
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			width: 100%;
			z-index: -1;
			background: #fff;
			transition: all ease-in-out 0.5s;
		}
		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 0;
			z-index: -1;
			background: #f9f9f9;
			transition: all ease-in-out 0.5s;
		}
		h3 {
			font-size: 16px;
			transition: all ease-in-out 0.5s;
			padding: 0;
		}
		.cbIcon {
			width: 60px;
			margin: 0 auto 30px;
			display: block;
			position: relative;
			z-index: 1;
			&:after {
				content: '';
				position: absolute;
				width: 100px;
				height: 100px;
				border-radius: 50%;
				top: 43%;
				left: 56%;
				z-index: -1;
				transform: translate(-50%, -50%);

				background: rgb(255, 255, 255);
				background: -moz-linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(216, 216, 216, 1) 99%);
				background: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(216, 216, 216, 1) 99%);
				background: linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(216, 216, 216, 1) 99%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#d8d8d8',GradientType=1 );
				opacity: 0.25;
			}
			img {
				display: block;
				width: 100%;
				transition: all ease-in-out 0.5s;
			}
		}
		&:hover {
			box-shadow: none;
			&::after {
				width: 0;
			}
			&::before {
				width: 100%;
			}
		}
	}
}

.ribbon {
	width: 96px;
	height: 96px;
	overflow: hidden;
	position: absolute;
	&::before {
		position: absolute;
		z-index: -1;
		content: '';
		display: block;
		border: 5px solid $bg-1;
	}
	&::after {
		position: absolute;
		z-index: -1;
		content: '';
		display: block;
		border: 5px solid $bg-1;
	}
	span {
		position: absolute;
		display: block;
		width: 179px;
		padding: 2px 0;
		background-color: $bg-10;
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
		color: $bg-6;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
		text-transform: uppercase;
		text-align: center;
		font-weight: 600;
		font-size: 13px;
	}
}
.ribbon-top-right {
	top: -10px;
	right: -10px;
	&::before {
		border-top-color: $bg-1;
		border-right-color: $bg-1;
		top: 0;
		left: 0;
	}
	&::after {
		border-top-color: $bg-1;
		border-right-color: $bg-1;
		bottom: 0;
		right: 0;
	}
	span {
		left: -29px;
		top: 30px;
		transform: rotate(45deg);
	}
}

.staffImg {
	padding: 30px 0;
	background: #f9f9f9;
	h2 {
		font-weight: 700;
		text-align: center;
	}
	img {
		width: 100%;
		display: block;
	}
}
