.item-box-wrap {
	background: #f5f7fb;
	border-radius: 10px;
	padding: 15px;
	margin-top: 15px;
	.items-list {
		&:not(:last-child) {
			margin-bottom: 10px;
		}
	}
	.items-list-head {
		text-transform: capitalize;
		border-left: 4px solid #78bc4c;
		padding: 0 0 0 8px;
		line-height: 1;
		margin-bottom: 6px;
		margin-top: 6px;
		color: #78bc4c;
	}
}
