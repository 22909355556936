@import 'assets/styles/variable.scss';

.videoBoxWrap {
	.videoWrap {
		flex: 0 0 48%;
		max-width: 48%;
		padding: 0 9px;
	}
	.boxWrap {
		flex: 0 0 52%;
		max-width: 52%;
		padding: 0 9px;
		h2 {
			font-size: 3.5vw;
			font-weight: 600;
		}
	}
}
.link_wrap {
	display: block;
	margin-top: 30px;
}
.link_text {
	position: relative;
	display: inline-block;
	color: #595959;
	padding-right: 0;
	font-size: 18px;
	font-weight: bold;
	line-height: 2;
}
.link_text:hover {
	color: #333;
}

.link_text::after {
	border: 1px solid rgba(0, 0, 0, 0.3);
	border-top-color: rgba(0, 0, 0, 0.3);
	border-top-style: solid;
	border-top-width: 1px;
	border-right-color: rgba(0, 0, 0, 0.3);
	border-right-style: solid;
	border-right-width: 1px;
	border-bottom-color: rgba(0, 0, 0, 0.3);
	border-bottom-style: solid;
	border-bottom-width: 1px;
	border-left-color: rgba(0, 0, 0, 0.3);
	border-left-style: solid;
	border-left-width: 1px;

	display: block;
	content: '';
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 2px;
	background-color: #fff;
	bottom: -4px;
	opacity: 0.8;
	left: 0;
	backface-visibility: hidden;
	pointer-events: none;
	animation: portSliderLine2 0.45s cubic-bezier(0.15, 0.75, 0.5, 1) forwards;

	margin-top: 0;
	top: auto;
	border-top: none;
	border-top-color: currentcolor;
	border-right: none;
	border-right-color: currentcolor;
	bottom: 0;
	border-left: none;
	border-left-color: currentcolor;
	border-bottom: 2px solid #000;
	border-bottom-color: rgb(0, 0, 0);
	border-radius: 0;
	background-color: transparent !important;
	transition: transform 0.4s cubic-bezier(0.23, 0.46, 0.4, 1);
	animation: none;
	transform-origin: left;
	z-index: 1;
}

.link_text::after {
	border-color: $bg-1 !important;
}

.link_wrap:hover .link_text::after {
	animation: none;
	transform: scaleX(0);
	transform-origin: right;
}

.nectar-highlighted-text em {
	position: relative;
	display: inline-block;
	font-family: inherit;
	font-size: inherit;
	font-style: inherit;
	font-weight: inherit;
	text-transform: inherit;
	letter-spacing: inherit;
	line-height: 1em;
	padding: 0 3px;
}
.nectar-highlighted-text:not([data-style='text_outline']) em {
	background-repeat: no-repeat;
	background-size: 0 80%;
	background-image: linear-gradient(to right, #000 0, #000 100%);
	/* transition: background-size .9s cubic-bezier(.15,.75,.4,1),opacity .25s ease; */
	background-position: left 90%;
}

.nectar-highlighted-text[data-style='full_text'] em {
	background-position: left 70%;
}
.nectar-highlighted-text[data-style='full_text'] em {
	display: inline;
}

.nectar-highlighted-text em.animated {
	background-size: 100% 80%;
}
.nectar-highlighted-text[data-style][data-user-color='true'] * {
	color: inherit;
}
.nectar-highlighted-text[data-color='#ffd74b']:not([data-style='text_outline']) em {
	background-image: linear-gradient(to right, #b2fde8 0, #b2fde8 100%);
}

.videoBoxWrap {
	.videoWrapInner {
		padding: 9px 0;
		height: 100%;
	}
}

.video-react.video-react-fluid {
	border-radius: 7px !important;
}

.video-react-poster {
	border-radius: 7px;
}

.video-react .video-react-video {
	border-radius: 7px;
}

.video-react.video-react-fluid {
	border-radius: 20px;
}

.video-react .video-react-big-play-button {
	font-size: 13em !important;
	line-height: 1em !important;
	height: 1.2em !important;
	width: 1.2em !important;
	top: 50% !important;
	left: 50% !important;
	border: 0.1em solid #ddfcbc !important;
	background-color: transparent !important;
	transition: all 0.4s !important;
	border-radius: 50% !important;
	transform: translate(-50%, -50%) !important;
	color: #ddfcbc !important;
	z-index: 2;
	&:hover {
		color: #fff !important;
		border-color: #fff !important;
	}
}

.clRev {
	margin-top: 30px;
	img {
		max-width: 100%;
	}
}

.boxWrapInner {
	padding-left: 30px;
}
