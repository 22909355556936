@media only screen and (max-width: 1135px) {
	.tetailPart {
		.title {
			font-size: 1.271em;
		}
	}
}
@media only screen and (max-width: 1024px) {
	.tetailPart {
		.title {
			font-size: 1em;
			margin-bottom: 0;
		}
		p {
			font-size: 0.8rem;
		}
		.stylishBtn a {
			padding: 4px 18px;
			font-size: 0.8rem;
		}
	}
}
@media only screen and (max-width: 767px) {
	.blogTitle {
		padding: 0.5rem 0;
	}
	.blog {
		.box-date {
			width: 72px;
			top: auto;
			left: 10px;
			bottom: 10px;
			background: rgba(255, 255, 255, 0.76);
			padding: 10px;
			backdrop-filter: blur(5px);
		}
		.date {
			color: #2f2f2f;
			font-size: 3.12em;
			line-height: 52px;
		}
		.month {
			font-size: 0.886em;
			right: 8px;
			top: 40px;
			line-height: normal;
		}
	}
}
