@media only screen and (max-width: 767px) {
	.testiContent {
		p {
			font-size: 12px;
			line-height: 1.7;
			&:after {
				bottom: -30px;
			}
		}
		.headIcon {
			img {
				height: 20px;
			}
		}
		.clintImg {
			flex: 0 0 48px;
			max-width: 48px;
		}
		.clintInfo {
			h5 {
				font-size: 13px;
				padding-bottom: 5px;
			}
			h6 {
				font-size: 11px;
				padding: 0;
			}
		}
	}
	.testimonials {
		.owl-stage-outer {
			padding: 50px 0 0 0;
		}
	}
}
