@media only screen and (max-width: 880px) {
	.carrerBanner {
		padding: 3rem 0;
		overflow: hidden;
	}
	.cbSlogan {
		h2 {
			font-size: 1.813rem;
		}
		p {
			font-size: 0.9rem;
			line-height: 1.5;
		}
	}
}
@media only screen and (max-width: 767px) {
	.carrerBanner {
		.col-lg-6 {
			position: relative;
			z-index: 1;
			&:last-child {
				order: -1;
				margin-bottom: 15px;
			}
		}
	}
	.cbImg {
		&:after {
			right: -0.938rem;
		}
	}
}
@media only screen and (max-width: 736px) {
	.carrerBanner {
		padding: 1.5rem 0;
	}
}
@media only screen and (max-width: 600px) {
	.carrerBanner {
		padding: 1rem 0;
	}
	.cbImg {
		padding-left: 0;
		&::before {
			width: 62%;
			height: 8.25rem;
			left: -0.95rem;
		}
		&:after {
			width: 7.25rem;
			height: 6.25rem;
			bottom: -1.125rem;
			opacity: 0.4;
		}
	}
}
