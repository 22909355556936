// @import 'assets/styles/style.scss';
@import 'assets/styles/variable.scss';
.banner {
	height: calc(100vh - 77px);
	position: relative;
	z-index: 1;
	width: 100%;
	flex-wrap: wrap;
	overflow: hidden;
	.bannerLeft {
		flex: 0 0 60%;
		max-width: 60%;
		padding: 0 55px;
		h6 {
			color: $bg-13;
			font-size: 3.5vw;
			font-family: 'Turbinado';
			padding-bottom: 0;
		}
		h2 {
			color: #2f2f2f;
			font-size: 4vw;
			font-family: 'Proxima Nova Bl';
			line-height: 1;
			letter-spacing: 0px;
			span {
				color: $bg-1;
			}
		}
		p {
			color: $bg-3;
			font-size: 1.6vw;
			font-family: 'Proxima Nova Rg';
		}
	}
	.bannerRight {
		flex: 0 0 40%;
		max-width: 40%;
		padding: 0 55px;
		svg {
			width: 100%;
			display: block;
		}
	}
}

.x-svg-wrapper {
	width: 100%;
	margin: 0 auto;
	svg {
		width: 100%;
		height: auto;
		.x-img-layer {
			fill: url(#x-pattern-img);
		}
		image {
			height: 100%;
			width: 100%;
			animation-name: textClip;
			animation-duration: 7s;
			animation-direction: alternate;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
		}
	}
}

.homeBanMobArea {
	display: none;
	position: relative;
	z-index: 1;
	margin-bottom: 1rem;
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		// background: rgba(1,194,141,.5);
		background: rgba(255, 255, 255, 0.8);
		backdrop-filter: blur(2.9px);
	}
	img {
		display: block;
		width: 100%;
	}
	.bannerLeft {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		flex: 0 0 100%;
		max-width: 100%;
		padding: 0 15px;
		// background: rgba(255, 255, 255, 0.09);
		padding: 2rem;
		// backdrop-filter: blur(5px);
		h2 {
			font-size: 5vw;
			padding-bottom: 5px;
			font-weight: 700;
			color: $bg-3;
			span {
				color: #0f7559;
			}
		}
		p {
			font-size: 3.4vw;
			line-height: 1.5;
			color: $bg-3;
			font-weight: 500;
		}
		h6 {
			color: #0f7559;
			font-size: 6vw;
			font-family: 'Turbinado';
			padding-bottom: 0;
			font-weight: normal;
		}
	}
}

@keyframes textClip {
	0% {
		x: -30%;
		y: -30%;
		transform: scale(0.6);
	}
	100% {
		x: -50%;
		y: -50%;
		transform: rotate(100deg) scale(0.4);
	}
}
