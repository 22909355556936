.multiple-img-wrap {
	position: relative;
	flex: 0 0 150px;
	max-width: 150px;
	// background-color: rgb(245 245 245);
	border: 1px solid rgb(160, 157, 157);
	border-radius: 0.375rem;
	overflow: hidden;
	img {
		height: 150px;
		object-fit: contain;
	}
	.image-del {
		position: absolute;
		top: 0;
		right: 0;
		border-radius: 0;
		box-shadow: none;
		outline: none !important;
		border: none;
		line-height: 1.5;
		font-size: 16px;
		background: #f00;
		padding: 0 8px;
		color: #fff;
	}
	&:not(:last-child) {
		margin-right: 15px;
	}
}

.image-upload-wrap {
	// flex: 0 0 150px;
	// max-width: 150px;
	.admim-input-wrap {
		position: relative;
		border: 1px solid rgb(160, 157, 157);
		width: 150px;
		height: 150px;
		margin-bottom: 0;
		border-radius: 0.375rem;
		input[type='file'] {
			opacity: 0;
			height: 100%;
		}
		.image-upload-lbl {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			text-align: center;
			font-size: 14px;
			font-weight: 600;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 10px;
			cursor: pointer;
			font-weight: 700;
			i {
				font-size: 40px;
				color: #78bc4c;
				margin-bottom: 5px;
			}
			.doc-count {
				position: absolute;
				bottom: -1px;
				background: #51ad16;
				left: 0;
				width: 100%;
				border-radius: 0 0 0.375rem 0.375rem;
				padding: 4px 0;
				color: #fff;
			}
		}
	}
}

.overfolow-hid {
	overflow: hidden;
}

.file-scroll {
	overflow-x: auto;
	padding-bottom: 10px;
	// &::-webkit-scrollbar {
	//     height: 4px;
	// }
}
