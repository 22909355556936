@import 'assets/styles/variable.scss';

.theProblem {
	padding: 3rem 0 4rem;
	background: #f9f9f9;
	position: relative;
	z-index: 1;
	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 28.125rem;
		height: 28.125rem;
		background: url('../../assets/images/common-image/q-color.png') 0 0 no-repeat;
		background-size: auto;
		background-size: contain;
		z-index: -1;
		opacity: 0.05;
		animation: jump30p 5s linear infinite;
	}
}
.theProblemInner {
	max-width: 50rem;
	width: 100%;
	margin: 0 auto;
	font-size: 1.125rem;
	line-height: 1.8;
	font-weight: 500;
	h2 {
		font-weight: 700;
	}
}
